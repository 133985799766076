<template>
  <validation-observer
    #default="{ invalid }"
  >
    <b-form>
      <b-row style="">
        <b-col md="6">
          <b-form-group
            label="Tipo"
            label-for="type"
          >

            <validation-provider
              #default="{ errors }"
              name="arquivo"
              rules="required"
            >

              <b-form-select
                id="type"
                v-model="type"
                :options="[
                  {
                    value: null, text: 'Selecione um Tipo',
                  },
                  {
                    value: 'DRAWEE', text: 'Sacado',
                  },
                  {
                    value: 'CEDENT', text: 'Cedente'
                  }
                ]"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col v-if="type === 'CEDENT'" md="6">
          <b-form-group label-for="credit_requested" label="Limite Requisitado">
            <b-form-input v-money="money" v-model="creditRequested" id="credit_requested"></b-form-input>
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group
            label="Arquivo"
            label-for="file"
          >

            <validation-provider
              #default="{ errors }"
              name="arquivo"
              rules="xlsx|required"
            >

              <b-form-file
                id="file"
                v-model="file"
                :state="errors.length > 0 ? false : null"
                placeholder="Arraste ou selecione um arquivo"
                drop-placeholder="Arraste o arquivo aqui."
                browse-text="Procurar"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col
          class="px-0"
          md="12"
        >
          <h4 class="ml-1">
            VADU
          </h4>

          <b-row class="px-1">
            <b-col md="6">
              <b-form-group
                label="Processos"
                label-for="process"
              >

                <validation-provider
                  #default="{ errors }"
                  name="process"
                  rules="required"
                >

                  <b-form-input
                    id="file"
                    v-model="process"
                    v-money="money"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group
                label="Protestos"
                label-for="process"
              >

                <validation-provider
                  #default="{ errors }"
                  name="Protesto"
                  rules="required"
                >

                  <b-form-input
                    id="file"
                    v-model="protest"
                    v-money="money"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group
                label="Recuperação Judicial"
                label-for="process"
              >

                <validation-provider
                  #default="{ errors }"
                  name="Recuperação Judicial"
                  rules="required"
                >

                  <b-form-select
                    id="file"
                    v-model="judicialRecovery"
                    :options="[
                      { value: null, text: 'Selecione se a empresa possui recuperação judicial deferida' },
                      { value: true, text: 'Possui'},
                      { value: false, text: 'Não Possui'},
                    ]"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>

        <b-col
          class="px-0"
          md="12"
        >
          <h4 class="ml-1">
            Divida Ativa
          </h4>

          <b-row class="px-1">
            <b-col md="6">
              <b-form-group
                label="Estadual"
                label-for="state_debt"
              >

                <validation-provider
                  #default="{ errors }"
                  name="Estadual"
                  rules="required"
                >

                  <b-form-input
                    id="file"
                    v-model="stateDebt"
                    v-money="money"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group
                label="Federal"
                label-for="process"
              >

                <validation-provider
                  #default="{ errors }"
                  name="Federal"
                  rules="required"
                >

                  <b-form-input
                    id="file"
                    v-model="federalDebt"
                    v-money="money"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-button
                variant="primary"
                :disabled="invalid"
                @click="$emit('imported-file', {
                  file,
                  federalDebt: removeSymbolsMoney(federalDebt),
                  stateDebt: removeSymbolsMoney(stateDebt),
                  process: removeSymbolsMoney(process),
                  protest: removeSymbolsMoney(protest),
                  judicialRecovery,
                  type,
                  creditRequested: type === 'CEDENT' ? removeSymbolsMoney(creditRequested) : undefined,
                })"
              >
                Importar
              </b-button>
            </b-col>
          </b-row>
        </b-col></b-row></b-form>
  </validation-observer>
</template>

<script>
import {
    BForm, BFormFile, BButton, BRow, BCol, BFormGroup, BFormInput, BFormSelect,
} from 'bootstrap-vue';

import '@validations';

import {
    ValidationProvider,
    ValidationObserver,
} from 'vee-validate';

export default {
    components: {
        BForm,
        BFormFile,
        BFormInput,
        BButton,
        BRow,
        BCol,
        BFormGroup,
        ValidationProvider,
        ValidationObserver,
        BFormSelect,
    },

    data() {
        return {
            file: null,
            type: null,
            stateDebt: 0,
            federalDebt: 0,
            judicialRecovery: null,
            creditRequested: 0,
            process: 0,
            protest: 0,
            money: {
                decimal: ',',
                thousands: '.',
                prefix: 'R$ ',
                precision: 2,
                masked: false,
            },
        };
    },

    methods: {
        removeSymbolsMoney(value) {
            return typeof value === 'string'
                ? (value.replace(/[R$ .]/g, '').replace(',', '.'))
                : value;
        },
    },
};
</script>
