<template>
  <b-container>
    <b-card>
      <b-button
        class="mb-2"
        variant="primary"
        @click="downloadBaseFile"
      >
        Baixar Modelo
      </b-button>

      <import-file-form
        @imported-file="importFile"
      />
    </b-card>
  </b-container>
</template>

<script>
import {
    BContainer, BCard, BButton,
} from 'bootstrap-vue';

import ImportFileForm from './components/Form.vue';
import BalanceService from '@/service/balance';

export default {
    components: {
        BContainer, BCard, ImportFileForm, BButton,
    },

    methods: {
        async importFile(file) {
            const isConfirmed = await this.confirmAnAction('Deseja realmente importar esse arquivo?');

            if (!isConfirmed) return;

            this.callLoading(true);

            const { status, data } = await BalanceService.importFile({
                ...file,
                user: this.$store.state.user.data.name,
            });

            this.callLoading(false);

            if (status > 200 && status <= 299) {
                this.modalSuccess('Arquivo importado com sucesso');
                this.$router.push(`/balanco/visualizar/${data.id}`);
                return;
            }

            const hasCNPJError = data.message?.includes('cnpj');

            if (hasCNPJError) {
                const cnpjValue = data.message.split(':')[1];
                this.modalError(`CNPJ Inválido: ${cnpjValue}`);
            } else {
                this.modalError('Erro ao importar arquivo');
            }
        },

        async downloadBaseFile() {
            const response = await BalanceService.getExample();
            const fileURL = window.URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.ms-excel' }));
            const fileLink = document.createElement('a');

            fileLink.href = fileURL;
            fileLink.setAttribute('download', 'balanco.xlsx');
            document.body.appendChild(fileLink);

            fileLink.click();
        },
    },
};
</script>
